<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNamewww"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>{{ editActivityName }}反馈
        </span>
        </div>
        <div class="cancelContent">
          <div class="selectBox">
            <div class="everyBox">
              <p class="lable"><span style="color: #D9161C">*</span>上传照片：</p>
              <div class="imgUpload">
                <a-upload
                  list-type="picture-card"
                  accept="image/png, image/jpeg, image/jpg"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                  :remove="handleRemove"
                  @preview="handlePreview"
                  style="display: flex;flex-direction: row-reverse"
                >
                  <div v-if="fileList.length<5">
                    <a-icon style="font-size:26px;color:#aaa" type="plus" />
                  </div>
                </a-upload>
                <span style="position: absolute;left: 298px;color: #AAAAAA;font-size: 14px;top: 100px;font-weight: 400;line-height: 20px" v-if="fileList.length<= 0">注：上传图片任意格式，附件大小不超过20M</span>
                <a-modal  class="previewVisible"  :maskClosable="false"   :closable="false" :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <div class="modal_close" @click="previewVisible=false">
                    <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
                  </div>
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-btn-wrap">
          <h-btn
            class="h-btn"
            :fontSize="16"
            :width="120"
            :height="40"
            style="background: #fff;color: #777;border: 1px solid #CCCCCC;text-shadow:none"
            @click="cancle"
          >取消</h-btn>
          <h-btn
            class="h-btn"
             :fontSize="16"
             :width="120"
             :height="40"
             @click="handleOk"
          >
            提交
          </h-btn>
        </div>
      </a-spin>
    </a-modal>

  </div>
</template>
<script>

import { publicPath } from "@/common/constant";
import axios from "axios";
import {
  findListLabel,
  getDefevtiveProByMatkl,
  potentialSpart,
  saveInfoLabel
} from "@/views/userOperations/IntendedUserEntry/api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { getQuestionTypes } from "@/views/questionnaire/components/api";
import { getUser, updateShopPotentialVisit } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {},
  data() {
    return {

      fileList:[],
      fileIds:[],// 附件id
      urlArr:[],// 附件地址
      previewImage: '',
      pageLoadFlag:false,
      previewVisible:false

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editActivityName: {
      type: String,
      default: ''
    },
  },

  watch: {
    visible: {
      handler: function(newval, oldval) {
        this.fileIds = []
        this.urlArr = []
        this.fileList = []
      },
      deep: true
    }

  },
  mounted() {


  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file1", file);
      formData.append("fileType", "shopactivity");
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      this.Canbutton = true
      const BASE_API = publicPath;
      axios.post(BASE_API + '/comm/method=uploadFile.nd', formData, config).then(res => {
        if (res.data.message == '上传成功') {
          this.fileIds.push(res.data.businessId)
          this.urlArr.push( res.data.url)
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
        this.Canbutton = false
      })
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1)
      this.urlArr.splice(index, 1);
    },

    handleOk() {
      // this.$emit("update:visible", false);
      this.$emit("cancelReasonHandOk",this.fileIds,this.urlArr);
      this.$forceUpdate()

    },
    cancle() {

      this.$emit("update:visible", false);
      this.$forceUpdate()

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNamewww {
  .ant-input{
    border: 1px solid #eee!important;
    width: 300px;
  }
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;
      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        .selectBox {
          display: flex;
          flex-direction: column;
          .everyBox {
            display: flex;
            //align-items: center;
            margin-left: 50px;
            margin-top: 24px;
            .lable {
              width: 120px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;
              margin-top: 30px;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }
            .imgUpload {
              /deep/.anticon{
                font-size: 22px;
              }

            }
            .ant-upload.ant-upload-select-picture-card {
              width: 88px;
              height: 88px;
            }
            .ant-upload-list {
              width: 340px;
            }
            .ant-upload-list-picture-card-container {
              width: 88px;
              height: 88px;
            }
            .ant-upload-list-picture-card .ant-upload-list-item {
              width: 88px;
              height: 88px;
            }

          }
          .everyBox:first-child{
            margin-top: 40px;
            margin-left: 68px;
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }
      .footer-btn-wrap {
        margin-top: 40px;
        padding-bottom: 30px;
        .h-btn:first-child{
          margin-right: 24px;
        }
      }



      .modal_close {
        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}
.previewVisible {
  position: relative;
  /deep/.ant-modal-content {
    border-radius: 8px!important;
  }
  .modal_close {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 0px;
    right: 0;
    line-height: 42px;
    border-radius: 0 10px 0 0;
    text-align: center;
    cursor: pointer;

    .modal_close_icon {
      color: #aaa;
      font-size: 16px;
    }
  }
  .modal_close:hover {
    background: #E4F8F7;

    .modal_close_icon {
      color: #08ABA8;
    }
  }
}


</style>


