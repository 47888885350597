import http from "@/utils/request";


/**
 * 上传图片
 * @param {
	 fileName: 文件名,
	 file: base64,
	 fileType: 'assessnotice'
 } data
 */
export function upload(data) {
	return http({
		method: "post",
		url: "/comm/uploadFileNew.nd",
		data: data
	});
}

// 提交问卷
export function subQuestionnaire(data) {
	return http({
		method: "post",
		contentType: "application/json;charset=UTF-8",
		type: 'stringfy',
		url: "/baseSurveyQuestion/saveQuestionSub.nd",
		data: data
	});
}

// 获取问卷详情
/**
 * @param {
	 questionId
 } data
 */
export function getQuestionnaire(data) {
	return http({
		method: "post",
		url: "/baseSurveyQuestion/getQuestionById.nd",
		data: data
	});
}

// 获取题目类型
/**
 * @param {
	 pid
 } data
 */
export function getQuestionTypes(data) {
	return http({
		method: "post",
		url: "/comm/dict.nd",
		data: data
	});
}