<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNameSale"
      :width="484"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>销售返利
        </span>
      </div>

      <div class="contentBox" >
        <div class="topText">
          <span>数据更新至{{updateTime}}</span>
          <div class="topBox" style="position: relative">
            <div class="everyBox">
              <p>销售总额</p>
              <p>{{totalAmount}}<span>元</span></p>
             
            </div>
            <div class="everyBox">
              <p>预计返利(元)</p>
              <p>{{rebate}}<span>元</span></p>
            
            </div>
          </div>
          <div class="bottom">
            <div class="line"></div>
            <div class="bottomBox">
              <p class="everyBox">
                <span class="imgBox">
                  <img src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/1.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=1778709576&Signature=LD72xQVxMSDQy3Wi7Na5bsnp%2BfE%3D" alt="">核销抖音券
                </span>
                <span class="numText">{{douyinNum}}<span>张</span></span>
              </p>
              <p class="everyBox">
                <span class="imgBox">
                  <img src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/2.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=1778709635&Signature=QDcoOEZTGSSkpjm%2FToXS8tffZpg%3D" alt="">以旧换新订单
                </span>
                <span class="numText">{{oldNewNum}}<span>单</span></span>
              </p>
              <p class="everyBox">
                <span class="imgBox">
                  <img src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/3.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=1778709651&Signature=kdVWnD%2BH5BCR8UimwOchBmKou1w%3D" alt="">小区罗盘获客
                </span>
                <span class="numText">{{guestNum}}<span>名</span></span>
              </p>
            </div>
          </div>
          
        </div>
       
        <p class="title">销售明细</p>
        <div class="oneEchartsBox" v-for="(item,index) in saleList" :key="index" v-show="saleList.length>0">
          <span class="name">{{item.matklName}}</span>
          <div class="oneEcharts" :id="'oneEcharts'+index" :ref="'oneEcharts'+index"></div>
          <span class="numBox"><span class="leftNum">{{item.realSale?item.realSale:0}}</span>/{{item.planSale}}</span>
        </div>
        <div class="noneBox" v-if="saleList.length<=0">
          <img :src="nolistImg" alt="">
          <p class="none">暂无销售明细~</p>
        </div>
      </div>

    </a-modal>

  </div>

</template>

<script>

import { salesRebate } from "@/views/monopolyShopActivity/campaignList/api";

export default {
  components:{
  },
  name: "salesRebate",
  data() {
    return {
      nolistImg: require("@/assets/order/none.png"),
      saleList:[],
      updateTime:'',
      totalAmount:0,
      rebate:0,
      douyinNum:0,
      oldNewNum:0,
      guestNum:0,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },

  },
  watch: {
    id:{
      immediate: true,
      handler(newValue) {
      }
    },
    visible: {
      immediate: true,
      handler(newValue) {
        if(newValue){
          this.getSaleInfo()
        }
      }
    },

  },
  mounted(){

  },

  methods: {
    // 获取数据
    getSaleInfo(){
      salesRebate({activityId:this.id}).then(res =>{
        if (res.data.code == 0){
          this.rebate=res.data.data.rebate?res.data.data.rebate:0
          this.totalAmount= res.data.data.totalAmount?res.data.data.totalAmount:0
          this.douyinNum= res.data.data.douyinNum?res.data.data.douyinNum:0
          this.oldNewNum= res.data.data.oldNewNum?res.data.data.oldNewNum:0
          this.guestNum= res.data.data.guestNum?res.data.data.guestNum:0
          
          if( res.data.data.saleInfoList.length>0){
            res.data.data.saleInfoList.forEach(item=>{
              item.guestNumRate =  item.saleRebate?item.saleRebate.split('%')[0]:0
                //
            })
          }
          this.saleList = res.data.data.saleInfoList
          this.updateTime = res.data.data.updateTime
          this.$nextTick(()=>{
            for(const key in this.saleList) {
              this.drawChartsone(key,this.saleList[key])
            }
          })
        }
      })
    },
    // 第一个div里面的图表
    drawChartsone(key,data) {
      var myChart = this.$echarts.init(document.getElementById("oneEcharts" + key));
      this.$forceUpdate()
      data.guestNumRate = data.guestNumRate&&data.guestNumRate > 0  ? data.guestNumRate : 0
      let value = data.guestNumRate> 100  ? 100 :  data.guestNumRate
      let option={
        // backgroundColor:"#EEF9F9",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            show: true,
            inverse: true,
            data: [],
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            zlevel: 1,
            name: '条',
            type: 'bar',
            yAxisIndex: 0,
            data: [value>0&&value<15?20:value<=0?10:value],
            barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 20,
                color: function () {
                  return '#18D1BC';
                },
              },
            },
            label: {
              normal: {
                show: true,
                fontSize: 12,
                position: 'inside',
                // formatter: '{c}%',
                formatter: function(name) {
                  return data.guestNumRate +'%';
                },
                padding:value>0&&value<15?[0 ,0 ,0, 3]:value<=0?[0 ,0 ,0, 0]:[0 ,0 ,0, 20],
                textStyle: {
                  color: '#fff',
                },
              },
            },
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },



    handleOk() {
      this.$emit("update:visible", false);
    },
    cancle() {
      this.$emit("update:visible", false);

    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNameSale {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding:30px 20px 27px 24px;

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 28px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .contentBox {
        .topText{
          width: 436px;
          //height: 112px;
          background: linear-gradient(90deg, #EEF9F9 0%, #FBFBFB 100%);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 8px 0 18px 12px;
          span{
            font-size: 12px;
            color: #AAAAAA;
            text-align: left;
          }
          .topBox{

            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-around;
            padding: 0 63px;
            p:last-child{
              font-size: 24px;
              font-weight: 500;
              color: #00AAA6;
              span{
                margin-left:4px;
                font-size:12px;
                color: #00AAA6;
                font-weight: 400;
              }
            }
            p:first-child{
              font-size: 14px;
              font-weight: 400;
              color: #262626;
            }
            .everyBox{
              margin-top:11px;
            }
          }
          .bottom{
            width:368px;
            // background-color: #e8fafa;
            display: flex;
            flex-direction: column;
            margin-left: 22px;
            .line{
              width:100%;
              height: 1px;
                background-image: linear-gradient(to right, #00AAA6 0%,#00AAA6 50%, transparent 50%);
                background-size: 8px 1px;
                background-repeat: repeat-x;
                margin-top:10px;
              // // margin-left:22px;
              // margin-top:10px;
              // border: 1px dashed rgba(0,170,166,0.2);
            }
            .bottomBox{
              display: flex;
              flex-direction: row;
              justify-content: center;
              margin-top:14px;

              .everyBox {
                // width:100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right:44px;
                .imgBox{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  img{
                    width:14px;
                    height:14px;
                    margin-right:4px;
                  }
                  span{
                    height: 17px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #777777;
                    line-height: 17px;
                    text-align: left;
                  }
                }
                .numText {
                  height: 30px;
                  font-weight: 500;
                  font-size: 22px;
                  color: #262626;
                  line-height: 30px;
                  span{
                    height: 17px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #262626;
                    line-height: 17px;
                    margin-left:2px;
                  }
                }
                
              }
              .everyBox:last-child{
                margin-right: 0;
              }
              
            }
          }
          
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          color: #262626;
          text-align: left;
          margin: 16px 0 4px 0;


        }
        .noneBox{
          display:flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .none {
            font-size: 14px;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
          }
          img{
            width: 150px;
            height: 121px;
          }
        }
        .oneEchartsBox{
          display:flex;
          align-items:center;
          margin-bottom:9px;
          .name{
            text-align: right;
            width: 70px;
            margin-right: 12px;
          }
          .numBox{
            color:#262626;
            font-size: 12px;
            font-weight: 500;
            .leftNum{
              color: #00AAA6;
              font-size: 14px;
            }
          }
          .oneEcharts{
            margin-right: 14px;
            width: 190px;
            height: 16px;
            position: relative;
            //top: 60px;
            //left: 24px;
            //background: #E1E1E1;
            //border-radius: 7px;
          }

        }
        .oneEchartsBox:last-child{
          margin-bottom: 0;
        }
      }


    }


    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:8px 0 18px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


