import sortBar from "./components/SortBar";
import  feedbackModal from './components/feedbackModal'
import salesRebate from './components/salesRebate'
import {
  activityList,
  feedbackApi,
  withdraw,
  deleteInfo,
  downPoster
} from "@/views/monopolyShopActivity/campaignList/api";
export default {
  components: {
    sortBar,
    feedbackModal,
    salesRebate
  },

  data() {
    return {
      salesRebateVisible:false,
      sortList: [
        {
          name: "全部",
          sortStatus: 2, //0默认 1降 2升序
        },
        {
          name: "进行中",
          sortStatus: 0, //0默认 1降 2升序
        },
        {
          name: "未开始",
          sortStatus: 0, //0默认 1降 2升序
        },
        {
          name: "已结束",
          sortStatus: 0, //0默认 1降 2升序
        },
      ],
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/campaignList",
          name: "campaignList",
          title: "专卖店活动"
        }
      ],
      param: {
          sortField: "", //排序字段，sale销量，onlineDate上架时间
          sortType: "desc", //排序类型，asc正序 或 desc倒序
          pageNum: 1, //页码
          pageSize: 10, //每页条数
      },
      pageLoading:false,
      datalist:[],
      feedbackVisible:false,
      editId:'',
      editActivityName:'',
      totalPage:0,
      visibleOpeation:false,
      opeationInfo:false,
      opeatorType:'',
      total:0,
      type:'',
      posterPreviewVisible:false,
    };
  },
  watch: {

  },
  computed: {
  },
  created() {

  },
  mounted() {
    this.getActivityList()
  },
  methods: {
    //编辑
    edit(item){
      this.$router.push({
        path: "/creatStoreActivities",
        query: {
          id: item.id,
          type: 'edit',
          editType:''
        }
      });
    },
    // 查看详情
    gotodetail(item){
      this.$router.push({
        path: "/IntentionList",
        query: {
          id: item.id,
          type: 'view',
          filter: "specialActivityName:" + item.activityTheme,
          // specialActivityId:item.id,
          onlyUserParam: true
        }
      });
    },
    // 下载
    downLoad(item){
      if(item.activityPoster) {
        let data = {
          urlString:item.activityPoster
        }
        downPoster(data).then(res=>{
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", item.activityTheme + ".jpg");
          document.body.appendChild(link);
          link.click();
        })
      } else {
        this.$message.warning('没有海报图片可下载')
      }

    },
    // 删除
    del(item){
      this.opeatorType = 'delete'
      this.opeationInfo = '确定删除？'
      this.visibleOpeation = true
      this.opeatorId = item.id
    },
    // 撤回
    withdrawOpen(item){
      this.opeatorType = 'withdraw'
      this.opeationInfo = '确定撤回？'
      this.visibleOpeation = true
      this.opeatorId = item.id
    },

    handleSubmit(){
      let data  = {
        activityId:this.opeatorId
      }
      if(this.opeatorType == 'withdraw' ){
        withdraw(data).then(res=>{
          if(res.data.code == 0){
            this.datalist = []
            this.$message.success(res.data.msg)
            this.param.pageNum = 1
            this.getActivityList()
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
      }
      if(this.opeatorType == 'delete' ){
        deleteInfo (data).then(res=>{
          if(res.data.code == 0){
            this.datalist = []
            this.$message.success(res.data.msg)
            this.param.pageNum = 1
            this.getActivityList()
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
      }

    },
    // 打开反馈的页面
    feedbackOpeation(item) {
      this.editActivityName=item.activityTheme
      this.editId = item.id
      this.feedbackVisible = true
    },
    saleRebate(item){
      this.editId = item.id
      this.salesRebateVisible = true
    },
    // 反馈确定
    feedbackBtn(fileId,fileUrl){
      let data = {
        id:this.editId,
        activityPic:fileId.join(','),
        serviceCode:'feedback',
      }
      feedbackApi(data).then(res=>{
        if(res.data.code == 0) {
          this.datalist = []
          this.feedbackVisible = false
          this.param.pageNum = 1
          this.getActivityList()
        } else {
          this.$message.warning(res.data.text)
        }
      })
    },
    // 获取列表
    getActivityList(){
      let data  = {
        serviceCode:'getActivitysDataPageNew',
        "export":false,
        "maxResults":50000,
        "orderBy":"id desc",
        "page":this.param.pageNum,
        "pageSize":this.param.pageSize,
        "queryParamList":[
          {
            "field":"status",
            "isShow":true,
            "isvalid":true,
            "items":[],
            "logic":"=",
            "title":"状态",
            "type":"string",
            "useFirstParam":false,
            "value": this.param.sortField,
            "values":[]
          },
          {
            "field":"account",
            "isShow":true,
            "isvalid":true,
            "items":[],
            "logic":"=",
            "title":"当前登录账号",
            "type":"string",
            "useFirstParam":false,
            "value":this.$store.state.user.userInfo.account.account ,
            "values":[]
          }
        ]
      }
      this.pageLoading = true
      activityList(data).then(res => {
        this.pageLoading = false
        // this.datalist = res.data.content
        this.total =  res.data.totalRows
        this.totalPage = res.data.totalPage
        this.datalist = this.datalist.concat(...res.data.content)
         var today = new Date();
        var today_time =  this.FormatDate(today);
        this.datalist.forEach(item=>{
          item.showBtn = false
          item.addTime = this.addDate(item.endTime,15)
          if(item.status == '已结束' && today_time > item.addTime){
            item.showBtn = false
          } else {
            item.showBtn = true
          }
        })
      })
    },

    FormatDate (strTime) {
      var date = new Date(strTime);
      return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
    } ,
    addDate(date, days) {
      var date = new Date(date);
      date.setDate(date.getDate() + days);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var mm = "'" + month + "'";
      var dd = "'" + day + "'";
      if(mm.length == 3) {
        month = "0" + month;
      }
      if(dd.length == 3) {
        day = "0" + day;
      }
      var time = year + "-" + month + "-" + day
      return time;
    },
    // 跳转新增页面
    gotoAddPage() {
      this.$router.push({
        path: "/creatStoreActivities",
        query: {
          type: 'add'
        }
      });
    },
    // 加载更多
    loadMore() {
      this.param.pageNum += 1
      this.getActivityList()

    },
    //排序调用
    sortFun(item) {
      if (item.name == "全部") {
        this.param.sortField = "";
      } else if (item.name == "进行中") {
        this.param.sortField = "进行中";
      } else if (item.name == "未开始") {
        this.param.sortField = "未开始";
      } else if(item.name == "已结束") {
        this.param.sortField = '已结束';
      }
      this.type = 'serach'
      this.datalist = []
      this.param.pageNum = 1
      this.getActivityList()
    },
  },
};
